.cartIn-enter {
  top: -56px;
}
.cartIn-enter-active {
  top: 0px;
  transition: top 500ms;
}
.cartIn-enter-done {
  top: 0px;
}
.cartIn-exit {
  top: 0px;
}
.cartIn-exit-active {
  top: -56px;
  transition: top 500ms;
}
.cartIn-exit-done {
  top: -56px;
}

.drawer-ltr-enter {
  left: -100vw;
}

.drawer-ltr-enter-active {
  left: 0;
  transition: left 500ms;
}

.drawer-ltr-enter-done {
  left: 0;
}

.drawer-ltr-exit {
  left: 0;
}

.drawer-ltr-exit-active {
  left: -100vw;
  transition: left 500ms;
}

.drawer-ltr-exit-done {
  left: -100vw;
}
