.react-datepicker.calendar-style {
  font-size: 14px;
  line-height: 16px;
  border: none;
}
.calendar-style .react-datepicker__header {
  background-color: #fff;
  border-width: 0px;
  padding-top: 0px;
}
.calendar-style .react-datepicker__day,
.calendar-style .react-datepicker__day-name {
  width: 42px;
  height: 42px;
  line-height: 42px;
  color: #161722;
  font-size: 14px;
  margin: 0;
}
.calendar-style .react-datepicker__day:hover {
  border-radius: 50%;
}
.calendar-style .line-through {
  text-decoration: line-through;
  color: #afafaf;
}
.calendar-style .gray {
  color: #afafaf;
}
.calendar-style .react-datepicker__day--keyboard-selected {
  background-color: #fff;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.calendar-style .react-datepicker__day--keyboard-selected:hover {
  background-color: #f0f0f0;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.calendar-style .react-datepicker__day--today,
.calendar-style .react-datepicker__month-text--today,
.calendar-style .react-datepicker__quarter-text--today,
.calendar-style .react-datepicker__year-text--today {
  font-weight: normal;
}

.calendar-custom .react-datepicker__day--keyboard-selected {
  background-color: transparent;
  color: #000;
}
.calendar-custom .react-datepicker__day--keyboard-selected:hover {
  background-color: #f0f0f0;
  color: #000;
}
.calendar-custom .react-datepicker__day--disabled {
  color: #ccc;
}
.calendar-custom .react-datepicker__day--disabled:hover {
  color: #ccc;
  background-color: transparent;
}
.calendar-custom .react-datepicker__day--in-range {
  background-color: #216ba5;
  color: #fff;
}
.calendar-custom .react-datepicker__day--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
  color: #fff;
}
.calendar-custom .calendar-slash{
  position: relative;
}
.calendar-custom .calendar-slash::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 16px;
  top: 0px;
  box-sizing: border-box;
  border-bottom: 1px solid #AEAEAE;
  transform-origin: bottom center;
  transform: rotateZ(-45deg);
}
@media (min-width: 400px) {
  .calendar-custom .calendar-slash::before {
    height: 24px;
  }
}

.react-datepicker.holiday-calendar-style {
  font-size: 14px;
  line-height: 16px;
  border: none;
}
.holiday-calendar-style .react-datepicker__header {
  background-color: #fff;
  border-width: 0px;
  padding-top: 0px;
}
.holiday-calendar-style .react-datepicker__day,
.holiday-calendar-style .react-datepicker__day-name {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #161722;
  font-size: 14px;
  margin: 2px;
  display: inline-flex;
  justify-content: center;
}
.holiday-calendar-style .react-datepicker__day:hover {
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: inline-flex;
  justify-content: center;
}
.holiday-calendar-style .gray-bg {
  background-color: #e5e7eb;
  color: #afafaf;
  pointer-events: none;
}
.holiday-calendar-style .gray {
  color: #afafaf;
}
.holiday-calendar-style .react-datepicker__day--keyboard-selected {
  background-color: #fff;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.holiday-calendar-style .react-datepicker__day--keyboard-selected:hover {
  background-color: #f0f0f0;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.holiday-calendar-style .react-datepicker__day--today,
.holiday-calendar-style .react-datepicker__month-text--today,
.holiday-calendar-style .react-datepicker__quarter-text--today,
.holiday-calendar-style .react-datepicker__year-text--today {
  font-weight: normal;
}

.calendar-custom .custom-date-high-light,.calendar-custom .custom-date-high-light:hover{
  background: #E5E7EB;
  border-radius: 4px;
}

.calendar-custom .react-datepicker__week{
  display: flex;
}

.calendar-style .multiple-custom-date-high-light,.calendar-style .multiple-custom-date-high-light:hover{
  background: #E5E7EB;
  border-radius: 50%;
}

.react-datepicker__children-container {
  margin: 0;
  padding: 0;
}
