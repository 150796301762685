/* .link-banner-image .slick-slide {
  margin-right: 40px;
} */

.slick-link-banner-dots {
  position: absolute;
  bottom: -24px;
  display: flex !important;
  height: 16px;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  flex-flow: row nowrap;
  justify-content: center;
}
@media (min-width: 1025px) {
  .slick-link-banner-dots {
    bottom: -18px;
    padding-right: 20px;
  }
}

.slick-link-banner-dots li {
  position: relative;
  display: inline-block;
  width: 6px;
  height: 6px;
  line-height: 16px;
  margin: 5px 3px;
  padding: 0;
  cursor: pointer;
  background: #747474;
  color: #747474;
  opacity: 0.4;
  border-radius: 3px;
}
.slick-link-banner-dots li.slick-active {
  width: 30px;
  height: 6px;
  opacity: 0.8;
}
.slick-link-banner-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 6px;
  height: 6px;
  padding: 5px;
  cursor: pointer;
  border: 0;
  outline: none;
}

.slick-top-product .slick-track {
  margin: 0;
}

.slick-slider .slick-top-product-dots {
  text-align: center;
  position: absolute;
  bottom: 0;
  list-style: none;
  margin: 0;
  width: 100%;
}
.slick-slider .slick-top-product-dots li {
  position: relative;
}

.slick-slider .slick-top-product-dots li {
  height: 8px;
  width: 8px;
  display: inline-block;
  margin: 0 8px;
  cursor: pointer;
  padding: 0;
  border-radius: 4px;
  background: rgba(29, 29, 29, 0.2);
}
.slick-slider .slick-top-product-dots li.slick-active {
  background: #a4a195;
  width: 32px;
}
.slick-slider .slick-top-product-dots li button {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 8px;
  width: 8px;
  background: transparent;
}
.slick-slider .slick-top-product-dots li.slick-active button {
  width: 32px;
}
.slick-top-product .slick-prev.slick-disabled:before,
.slick-top-product .slick-next.slick-disabled:before {
  opacity: 0;
}

.slick-top-product .slick-track {
  margin: 0;
}

.slick-slider .slick-top-product-dots {
  text-align: center;
  position: absolute;
  bottom: 0;
  list-style: none;
  margin: 0;
  width: 100%;
}
.slick-slider .slick-top-product-dots li {
  position: relative;
}

.slick-slider .slick-top-product-dots li {
  height: 8px;
  width: 8px;
  display: inline-block;
  margin: 0 8px;
  cursor: pointer;
  padding: 0;
  border-radius: 4px;
  background: rgba(29, 29, 29, 0.2);
}
.slick-slider .slick-top-product-dots li.slick-active {
  background: #a4a195;
  width: 32px;
}
.slick-slider .slick-top-product-dots li button {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 8px;
  width: 8px;
  background: transparent;
}
.slick-slider .slick-top-product-dots li.slick-active button {
  width: 32px;
}
.slick-top-product .slick-prev.slick-disabled:before,
.slick-top-product .slick-next.slick-disabled:before {
  opacity: 0;
}
