.slick-dots li.slick-active button:before {
  color: orange !important;
}

.slick-prev:before {
  font-size: 40px !important;
}

.slick-next:before {
  font-size: 40px !important;
}

.slick-prev {
  width: fit-content !important;
  height: fit-content !important;
  left: 0px !important;
  z-index: 2;
}

.slick-next {
  width: fit-content !important;
  height: fit-content !important;
  right: 0px !important;
  z-index: 2;
}
